.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.Navigation-panel {
    display: flex;
    justify-items: center;
    align-items: center;
    /* position: absolute; */
    top: 0;
    background-color: black;
    width: 100%;
    padding: 1rem;
    height: auto;
    z-index: 1;
    position: absolute;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.No-Scroll-Bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.No-Scroll-Bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.overflow-wrap-break-word {
  overflow-wrap: break-all
}

ul li a {
    color: #cdb19e;
}

ul li a:hover {
    text-decoration: underline;
    transform: scale(1.1);
}

ul li a.active {
    text-decoration: underline;
    font-weight: bolder;
    transform: scale(1.05);
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}